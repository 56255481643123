import { createContext } from "react";

export const PortalContext = createContext({
  deviceMode : "windows",
  setDeviceMode : () => {},
  ipadOnboarded: "checking",
  setIpadOnboarded: () => {},
  ipadOnboardRequested: false,
  setIpadOnboardRequested: () => {},
  macosOnboarded: false,
  setMacosOnboarded: ()=>{},
  windowsPolicyManagement: "pending",
  setWindowsPolicyManagement: ()=>{},
  ipadPolicyManagement: "pending",
  setIpadPolicyManagement: ()=>{},
  taskOnProgress:[],
  setTaskOnProgress:()=>{},
  apiToken:false,
  setApiToken:()=>{},
  numiatoStatus:"1000",
  setNumiatoStatus:()=>{},
  macosPolicyManagement:"pending",
  setMacosPolicyManagement:()=>{}
})

export const DeviceStatusContext = createContext({
  deviceStatus : null,
  setDeviceStatus : () => {}
})