import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getApi, postApi } from "../tools/axiosInstances";
import PortalAdminsTable from "../components/settings/PortalAdminsTable";
import AddAdminModal from "../components/settings/AddAdminModal";
import RemoveAdminModal from "../components/settings/RemoveAdminModal";
import LoadingTable from "../components/layout/loading/LoadingTable";
import NotificationModal from "../components/layout/NotificationModal";
import AccountSettings from "../components/settings/AccountSettings";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import EditAdminModal from "../components/settings/EditAdminModal";
import IpadAuthorizationInfo from "../components/settings/IpadAuthorizationInfo";
import ActionButton from "../components/layout/buttons/ActionButton";
import { ReactComponent as SwitchIcon } from "../components/icons/switch.svg";
import { ReactComponent as EditIcon } from "../components/icons/edit.svg";
import { ReactComponent as AddIcon } from "../components/icons/add.svg";
import { ReactComponent as RemoveIcon } from "../components/icons/remove.svg";
import { PortalContext } from "../PortalContext";
import { useGetLicenseStatus } from "../tools/utilties";
import SettingsSidebar, {
  SettingsSidebarItem,
} from "../components/settings/SettingsSidebar";
import PolicyManagementInfo from "../components/settings/PolicyManagementInfo";
function Settings() {
  const { t } = useTranslation("global");
  const [filter, setFilter] = useState("account");
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [portalAdmins, setPortalAdmins] = useState([]);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [removeAdminModal, setRemoveAdminModal] = useState(false);
  const [removeAllAlertModal, setRemoveAllAlertModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [ipadActivation, setIpadActivation] = useState(false);
  const { ipadOnboarded, setIpadOnboarded } =
    useContext(PortalContext);
  const numiatoStatus = useGetLicenseStatus();
  const toggleAddAdmin = () => {
    setAddAdminModal(!addAdminModal);
  };
  const handleEditAdmin = () => {
    // if (portalAdmins.length == selectedAdmins.length) {
    //   console.log("should be shown");
    //   toggleRemoveAllAlert();
    // } else
    if (selectedAdmins.length > 0) {
      toggleEditAdmin();
    }
  };
  const toggleRemoveAllAlert = () => {
    setRemoveAllAlertModal(!removeAllAlertModal);
  };
  const toggleEditAdmin = () => {
    setEditAdminModal(!editAdminModal);
  };
  const handleRefresh = () => {
    setPortalAdmins([]);
    setRefresh((p) => p + 1);
  };
  const handleActivate = () => {
    const userInfo = JSON.parse(sessionStorage.getItem("me"));
    postApi("/settings/ipad/requestonboard/", { userInfo: userInfo })
      // Call the API to send the notification
      .then(() => {
        setIpadOnboarded("requested");
      });
  };
  useEffect(() => {
    setLoading(true);
    getApi("/settings/portal-users/", {}).then((data) => {
      setPortalAdmins(data.data);
      setLoading(false);
    });
  }, [refresh]);
  const handleRemoveAdmin = () => {
    if (portalAdmins.length === selectedAdmins.length) {
      toggleRemoveAllAlert();
    } else if (selectedAdmins.length > 0) {
      toggleRemoveAdmin();
    }
  };
  const toggleRemoveAdmin = () => {
    setRemoveAdminModal(!removeAdminModal);
  };
  return (
    <>
      {/* Title Bar*/}
      <div className="w-full h-[108px] shadow-md ">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">
            {t("pages.settings.title")}
          </div>
          {/*Buttons*/}
          <div className="justify-end flex">
            {filter === "access" && role === "smp.administrator" ? (
              <span className="flex">
                <RefreshButton
                  loading={loading}
                  handleRefresh={handleRefresh}
                />
                <ActionButton
                  Icon={RemoveIcon}
                  onClick={handleRemoveAdmin}
                  id="remove"
                  disabled={!(selectedAdmins.length > 0)}
                  tooltip="tooltip.settings.remove_admin"
                  subtle
                >
                  {t("pages.settings.remove")}
                </ActionButton>
                <ActionButton
                  Icon={EditIcon}
                  onClick={handleEditAdmin}
                  id="edit"
                  disabled={!(selectedAdmins.length > 0)}
                  tooltip="tooltip.settings.edit_admin"
                  subtle
                >
                  {t("words.edit")}
                </ActionButton>
                <ActionButton
                  Icon={AddIcon}
                  onClick={() => toggleAddAdmin()}
                  id="add"
                  tooltip="tooltip.settings.add_admin"
                  subtle
                >
                  {t("pages.settings.add")}
                </ActionButton>
              </span>
            ) : filter === "ipad" && !(ipadOnboarded === "requested") ? (
              <>
                <ActionButton
                  id="activate"
                  onClick={handleActivate}
                  Icon={SwitchIcon}
                  tooltip="tooltip.settings.activate_ipad"
                  secondary
                >
                  {t("words.activate")}
                </ActionButton>
              </>
            ) : (
              <div className="h-6"></div>
            )}
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="mx-auto p-5 flex h-[calc(100vh-12rem)]">
        {/* Side separator */}
        <div className="min-w-[calc((100%-72rem)/2)]"></div>
        {/* SideBar */}
        <div className="min-w-64 justify-end mr-5 mb-5 overflow-y-auto">
          <SettingsSidebar filter={filter} setFilter={setFilter}>
            <SettingsSidebarItem
              handleOnClick={() => setFilter("account")}
              active={filter == "account"}
            >
              {t(`pages.settings.account_settings`)}
            </SettingsSidebarItem>
            <SettingsSidebarItem
              handleOnClick={() => setFilter("access")}
              active={filter == "access"}
            >
              {t(`pages.settings.access_settings`)}
            </SettingsSidebarItem>
            {numiatoStatus && (
              <SettingsSidebarItem
                handleOnClick={() => setFilter("ipad")}
                active={filter == "ipad"}
              >
                {t(`pages.settings.ipads.title`)}
              </SettingsSidebarItem>
            )}
            <SettingsSidebarItem
              handleOnClick={() => setFilter("policy")}
              active={filter == "policy"}
            >
              {t(`pages.settings.policy.title`)}
            </SettingsSidebarItem>
          </SettingsSidebar>
        </div>
        {/* Content */}
        <div className="max-w-[calc(100%-calc((100%-72rem)/2)-18rem)] overflow-scroll mb-12">
          <div className="min-w-[52rem] ">
            {filter === "access" ? (
              <>
                {/* <PeopleSelector setSelected={setselectedPeople} /> */}
                {portalAdmins.length > 0 ? (
                  <PortalAdminsTable
                    setSelected={setSelectedAdmins}
                    userInfo={portalAdmins}
                  />
                ) : (
                  <LoadingTable />
                )}
              </>
            ) : filter == "account" ? (
              <AccountSettings />
            ) : filter == "policy" ? (
              <PolicyManagementInfo />
            ) : (
              <IpadAuthorizationInfo />
            )}
          </div>
        </div>
      </div>
      {addAdminModal && (
        <AddAdminModal toggle={toggleAddAdmin} refresh={handleRefresh} />
      )}
      {editAdminModal && (
        <EditAdminModal
          toggle={toggleEditAdmin}
          selected={selectedAdmins}
          refresh={handleRefresh}
          allAdmins={portalAdmins}
        />
      )}
      {removeAdminModal && (
        <RemoveAdminModal
          toggle={toggleRemoveAdmin}
          selectedAdmins={selectedAdmins}
          refresh={handleRefresh}
        />
      )}
      {removeAllAlertModal && (
        <NotificationModal
          toggle={toggleRemoveAllAlert}
          title={"pages.settings.modal.remove_all.title"}
          body={"pages.settings.modal.remove_all.body"}
        />
      )}
    </>
  );
}

export default Settings;
